import React from 'react';

import Porftolio from './components/Portfolio';

import './assets/css/main.css'
import './assets/css/fontawesome-all.min.css'


function App() {

  return (
    <div>
<Porftolio />
    </div>
  );
}

export default App;
